import { Images } from "../client/Images";
import { Posts } from "../client/Posts";

export class ApiService {
    private images: Images; 
    private posts: Posts; 
    private password: string = "";
    private baseUrl: string;

    constructor() {
        this.baseUrl = "http://127.0.0.1:7266/api"
        this.baseUrl = "https://amelia-bob-functions.azurewebsites.net/api"
        this.images = new Images({
            baseUrl: this.baseUrl
        });
        this.posts = new Posts({
            baseUrl: this.baseUrl
        });
        this.password = localStorage.getItem("password") ?? "";
    }

    public setPassword(password: string) {
        this.password = password;
        localStorage.setItem("password", password);
    }

    async getPosts() {
        try {
            const posts = (await this.posts.getPostsGet({ headers: { "Authorization": this.password } })).data

            for (const post of posts) {
                post.content = post.content?.replace(/!\[[^\]]*\]\(([^)]*)\)/g, (match, num) => {
                    return match.replace(num, this.getImageUrl(num));
                });
            }
            return posts;
        } catch (error) {
            if ((error as Response).status === 401) {
                throw(error);   
            }
            return [];
        }
    }

    async createPost(title: string, content: string, date: Date, thumbnail: string | null) {
        const result = (await this.posts.createPostPost({
            title: title,
            content: content,
            date: date.toISOString(),
            thumbnail: thumbnail ?? undefined
        }, { headers: { "Authorization": this.password } }));
        return result.data;
    }

    async updatePost(id: string, title: string, content: string, date: Date, thumbnail: string | null) {
        const result = (await this.posts.updatePostPut({
            id: id,
            title: title,
            content: content,
            date: date.toISOString(),
            thumbnail: thumbnail ?? undefined
        }, { headers: { "Authorization": this.password } }));
        return result.data;
    }

    async deletePost(id: string) {
        await this.posts.deletePostDelete(id, { headers: { "Authorization": this.password } });
    }

    async uploadImage(file: File) {
        try {
            const result = await fetch(`${this.baseUrl}/images`, {
                method: "POST",
                body: file,
                headers: { "Authorization": this.password }
            });
            return await result.json();
        } catch (error) {
            if ((error as Response).status === 401) {
                throw(error);   
            }
            return [];
        }
    }

    async getImages() {
        try {
            const images = (await this.images.getAllImagesGet({ headers: { "Authorization": this.password } })).data
            return images;
        } catch (error) {
            return [];
        }
    }

    public getImageUrl(guid: string) {
        return this.baseUrl + "/images/" + guid;
    }
}