import React from "react";
import "./header.scss";
import moment from "moment";

type Props = {};
type State = {
    timeTogether: number,
    interval: any
};

const startTime = 1600715400;

export class Header extends React.Component<Props, State> {
    
    constructor(props: any) {
        super(props);
        this.state = {
            timeTogether: ((new Date()).getTime() / 1000) - startTime,
            interval: null
        };
    }

    componentDidMount() {
        this.setState({
            interval: setInterval(this.updateTimeTogther.bind(this), 1000)
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    updateTimeTogther() {
        this.setState({
            timeTogether: ((new Date()).getTime() / 1000) - startTime
        });
    }

    getTimeTogether() {
        const startDate = moment(startTime * 1000);
        const now = moment();

        const years = now.diff(startDate, "years");
        const days = now.diff(startDate, "days");
        const hours = Math.floor(this.state.timeTogether / 3600);
        const minutes = Math.floor(this.state.timeTogether / 60);

        return `${years > 0 ? years + " Years, " : ""}${days % 365 + " Days, "}${hours % 24 + " Hours, "}${minutes % 60 + " Minutes, "} and ${Math.floor(this.state.timeTogether % 60)} seconds`;
    }

	public render() {
        return <div className="header">
            <div className="header-content">
                <div className="header-title">
                    <span>Bob </span>
                    <span className="heart">❤</span>
                    <span> Amelia</span>
                </div>
                <div className="header-time-together">
                    <span>Together for </span><span>{ this.getTimeTogether() }</span>
                </div>
            </div>
        </div>
    }
};
