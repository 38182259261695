import React from "react";
import { Post } from "../../client/data-contracts";
import { ApiService } from "../../services/ApiService";
import "./Editor.scss";
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/theme-monokai";
import { UrlHelper } from "../../helpers/urlhelper";
import { ImagePicker } from "./ImagePicker/ImagePicker";

type Props = {
    api: ApiService,    
    indexSelected: (index: number) => void
};
type State = {
    posts: Post[],
    images: string[],
    selectedPost: Post | null,
    content: string,
    title: string,
    date: Date,
    thumbnail: string | null
};

export class Editor extends React.Component<Props, State> {
    
    constructor(props: any) {
        super(props);
        this.state = {
            posts: [],
            images: [],
            selectedPost: null,
            content: "",
            title: "",
            date: new Date(),
            thumbnail: null
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    public async fetchData() {
        const postsPromise = this.props.api.getPosts();
        const images = this.props.api.getImages();

        this.setState({
            posts: await postsPromise,
            images: await images,
        }, () => {
            let index;
            if (this.state.selectedPost)
                index = this.state.posts.findIndex(x => x.id === this.state.selectedPost?.id);
            else
                index = UrlHelper.getPageNumber();
                
            if (this.state.posts[index])
                this.selectPost(this.state.posts[index]);
            else
                this.setState({ selectedPost: null, content: "", title: "", date: new Date(), thumbnail: null})
        });
    }

    public selectPost(post: Post) {
        this.setState({ 
            selectedPost: post, 
            content: post.content as string, 
            title: post.title as string, 
            date: new Date(Date.parse(post.date as string)),
            thumbnail: post.thumbnail ?? null
        });
        this.props.indexSelected(this.state.posts.indexOf(post));
    }

    public async update() {
        await this.props.api.updatePost(
            this.state.selectedPost?.id ?? "",
            this.state.title,
            this.state.content,
            this.state.date,
            this.state.thumbnail
        );
        
        await this.fetchData();
    }

    public async new() {
        const guid = await this.props.api.createPost("Title", "# Content", new Date(), null);
        
        this.selectPost({ id: guid, date: new Date().toISOString(), title: "Title", content: "# Content" });
        await this.fetchData();
    }

    public async delete() {
        await this.props.api.deletePost(this.state.selectedPost?.id as string);
        
        const index = 0;
        this.selectPost(this.state.posts[index]);
        await this.fetchData();
    }    

	public render() {
        return <div className="editor">
            <div className="editor-sidebar">
                <div className="post-list">
                    { this.state.posts.length > 0 ? <>
                    { this.state.posts.map(post => 
                            <div 
                                className={`post-item ${post.id === this.state.selectedPost?.id ? "selected" : ""}`} 
                                key={post.id}
                                onClick={() => this.selectPost(post)}>
                                { post.title }
                            </div>)
                        }
                    </> : <>
                        <div className="button new-button" onClick={this.new.bind(this)}>New</div>
                    </>}
                </div>
            </div>
            <div className="editor-body">
                { this.state.selectedPost != null ? <>          
                    <div className="editor-edit">
                        <div className="editor-fields">
                            <input 
                                type="text" 
                                value={this.state.title} 
                                onChange={(x) => this.setState({ title: x.target.value })}
                            />
                            <input 
                                type="date" 
                                value={`${this.state.date.toISOString().slice(0, 10)}`} 
                                onChange={(x) => this.setState({ date: new Date(Date.parse(x.target.value)) })}
                            />
                            <ImagePicker 
                                api={this.props.api} 
                                value={this.state.thumbnail} 
                                onChange={(x) => this.setState({ thumbnail: x})}
                            />
                        </div>
                        <div className="editor-buttons">                                
                            <div className="button new-button" onClick={this.new.bind(this)}>New</div>
                            <div className="button update-button" onClick={this.update.bind(this)}>Update</div>
                            <div className="button delete-button" onClick={this.delete.bind(this)}>Delete</div>
                        </div>
                        <AceEditor
                            mode="markdown"
                            theme="monokai"
                            readOnly={false}
                            value={this.state.content}
                            onChange={(value) => this.setState({content: value})}
                            width="100%"
                            height="100%"
                            fontSize={14}
                            showPrintMargin={false}
                            showGutter={false}
                            highlightActiveLine={false}
                            setOptions={{
                                useWorker: false,
                            }}
                        />
                    </div>
                    <div className="editor-preview">
                        { this.state.thumbnail ? <>
                            <img src={this.props.api.getImageUrl(this.state.thumbnail as string)} alt=""/>
                        </> : <></>}
                        <ReactMarkdown children={this.state.content ?? ""} remarkPlugins={[remarkGfm]} />
                    </div>
                </> : <></>}  
            </div>
        </div>
    }
};
