import React from "react";
import "./wizard.scss";
import { WizardPage } from "./WizardPage";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { UrlHelper } from "../../helpers/urlhelper";
import { Page } from "../page/page";
import { ApiService } from "../../services/ApiService";

type Props = {
    api: ApiService,
    selectedIndex: number,
    pages: WizardPage[],
    indexSelected: (index: number) => void
};

type State = {};

export class Wizard extends React.Component<Props, State> {
    
    private handleKeyBind: (event: KeyboardEvent) => void;

    constructor(props: any) {
        super(props);

        this.handleKeyBind = this.handleKey.bind(this);
    }    

    componentDidMount() {
        const index = UrlHelper.getPageNumber();
        this.goToPage(index);
        
        document.body.addEventListener("keydown", this.handleKeyBind);
    }

    componentWillUnmount() {
        document.body.removeEventListener("keydown", this.handleKeyBind);
    }

    public previous() {
        if (this.props.selectedIndex > 0) {
            this.goToPage(this.props.selectedIndex - 1);
        }
    }

    public next() {
        if (this.props.selectedIndex < this.props.pages.length - 1) {
            this.goToPage(this.props.selectedIndex + 1);
        }
    }

    public goToPage(index: number) {
        this.updateTimelineScroll(index);
        this.props.indexSelected(index);
    }

    public handleKey(event: KeyboardEvent) {
        switch (event.code) {
            case "ArrowLeft":
            case "KeyA":
                event.preventDefault();
                this.previous();
                break;
            case "ArrowRight":
            case "KeyD":
                event.preventDefault();
                this.next();
                break;
        }
    }

    public updateTimelineScroll(index: number) {
        if (!this.props.pages[index] || !this.props.pages[index].date)
            return;

        const pixels = this.getPixelsBetweenDates(this.props.pages[index].date as Date, this.props.pages[0].date as Date);
        const wrapper = document.querySelector(".wizard-timeline-wrapper") as HTMLElement;
        wrapper.scrollLeft = (pixels + index * (32 + 4)) - (wrapper.clientWidth * 0.5);
    }

	public render() {
        if (!this.props.pages[this.props.selectedIndex])
            return;

        return <div className="wizard">
            <div className="wizard-timeline-vertical-wrapper">
                <div className="wizard-timeline-wrapper">
                    { this.renderTimeline() }
                </div>
            </div>
            <Page>
                <div className="page-render">
                    {(this.props.pages[this.props.selectedIndex].title || this.props.pages[this.props.selectedIndex].date) ? <>
                        <div className="wizard-header">
                            <div className="page-header">
                                { this.props.pages[this.props.selectedIndex].title ? <>
                                    <span className="page-title">
                                        { this.props.pages[this.props.selectedIndex].title }
                                    </span>
                                </> : <></>}
                                { this.props.pages[this.props.selectedIndex].date ? <>
                                    <span className="page-date">
                                        ({ this.props.pages[this.props.selectedIndex].date?.toLocaleDateString() })
                                    </span>
                                </> : <></>}
                            </div>
                        </div>
                    </> : <></>}
                    
                    <div className="wizard-content">
                        { this.props.pages[this.props.selectedIndex].markdown ? <>
                            <ReactMarkdown children={this.props.pages[this.props.selectedIndex].markdown} remarkPlugins={[remarkGfm]} />
                        </> : <></>}
                    </div>
                </div>
                <div className="page-thumbnail">
                    { this.props.pages[this.props.selectedIndex].thumbnail ? <>
                        <img src={this.props.api.getImageUrl(this.props.pages[this.props.selectedIndex].thumbnail as string)} key={this.props.pages[this.props.selectedIndex].thumbnail} alt=""/>
                    </> : <></>}
                </div>
            </Page>
            <div className="wizard-overlay">
                <div className="wizard-previous-button" onClick={() => this.previous()}>Previous</div>
                <div className="wizard-next-button" onClick={() => this.next()}>Next</div>
            </div>
        </div>
    }

    public renderTimeline() {
        return <div className="wizard-timeline">
            { this.props.pages.map((x, i) => <div className="wizard-timeline-time" key={i}>
                { i > 0 && this.props.pages[i].date != null && this.props.pages[i - 1].date != null ?
                    <div className="wizard-date-spacer" style={{width: this.getPixelsBetweenDates(this.props.pages[i].date as Date, this.props.pages[i - 1].date as Date)}}></div> :
                    <></>
                }
                <div className={`wizard-date-point ${(i === this.props.selectedIndex ? "active" : "")}`} onClick={() => this.goToPage(i)}>
                    <div className="wizard-date-point-details">
                        <div className="wizard-date-point-date">{ x.date?.toLocaleDateString() }</div>
                        <div className="wizard-date-point-title">{ x.title }</div>
                    </div>
                </div>
            </div>)}
            <div className="wizard-timeline-timebar"></div>
        </div>
    }

    getPixelsBetweenDates(a: Date, b: Date) {
        return ((+a) - (+b)) / 10000000;
    }
};
