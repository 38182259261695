import React from "react";
import "./body.scss";
import { Wizard } from "../wizard/wizard";
import { WizardPage } from "../wizard/WizardPage";
import { ApiService } from "../../services/ApiService";
import { PasswordPrompt } from "../password-prompt/PasswordPrompt";
import { Editor } from "../editor/Editor";
import { UrlHelper } from "../../helpers/urlhelper";
import { Header } from "../header/header";

type Props = {};
type State = {
    children: WizardPage[],
    api: ApiService,
    showPasswordPrompt: boolean,
    isEditMode: boolean,
    selectedPage: number
};

export class Body extends React.Component<Props, State> {
    
    constructor(props: any) {
        super(props);
        this.state = {
            children: [],
            api: new ApiService(),
            showPasswordPrompt: false,
            isEditMode: document.location.pathname.startsWith("/edit"),
            selectedPage: UrlHelper.getPageNumber()
        };
    }

    componentDidMount() {
        this.fetchPosts();
    }

    public setChildren(children: WizardPage[]){
        this.setState({ children: children })
    }

    public async fetchPosts() {
        try {
            const posts = await this.state.api.getPosts();
            const pages = posts.map(x => new WizardPage(x.content as string, x.title as string, new Date(Date.parse(x.date as string)), x.thumbnail as string));
            this.setChildren(pages); 
        } catch (error) {
            this.setState({ showPasswordPrompt: true });
        }
    }

    public handlePasswordChange(password: string) {
        this.setState({ showPasswordPrompt: false}, () => {
            this.state.api.setPassword(password);
            this.fetchPosts();
        });
    }

    public toggleEditMode() {
        this.setState({ isEditMode: !this.state.isEditMode }, () => {
            this.updateUrl();
            if (!this.state.isEditMode)
                this.fetchPosts();
        });
    }

    public updateIndex(index: number) {
        this.setState({selectedPage: index}, () => this.updateUrl());
    }

    public updateUrl() {
        window.history.pushState({}, "", `${this.state.isEditMode ? "/edit" : ""}/${this.state.selectedPage}`);
    }

	public render() {
        if (this.state.isEditMode)
            return <div className="body">
                <Editor api={this.state.api} indexSelected={this.updateIndex.bind(this)}></Editor>
                <div className="edit-button" onClick={this.toggleEditMode.bind(this)}>✎</div>
            </div>;

        return <div className="body">
            { this.state.showPasswordPrompt ? 
                <PasswordPrompt passwordChanged={this.handlePasswordChange.bind(this)}></PasswordPrompt> : 
                <>        
                    <Header></Header>        
                    <Wizard api={this.state.api} pages={this.state.children} selectedIndex={this.state.selectedPage} indexSelected={this.updateIndex.bind(this)}></Wizard>
                    <div className="edit-button" onClick={this.toggleEditMode.bind(this)}>✎</div>
                </>
            }
        </div>;
    }
};
