import * as React from "react";
import { Body } from "./components/body/body";
import "./App.scss";

type Props = {};

type State = {};

export class App extends React.Component<Props, State> {    
  constructor(props: any) {
      super(props);
      this.state = {};
  }

  public render() {
    return <div className="app">
      <Body></Body>
    </div>
  }
}

export default App;