export class UrlHelper{
    public static getPageNumber() {
        const index = document.location.pathname.lastIndexOf("/");
        if (index >= 0) {
            const number = document.location.pathname.substring(index + 1);
            const numerical = Number(number);
            return numerical;
        }

        return 0;
    }
}