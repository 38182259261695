import React from "react";
import "./PasswordPrompt.scss";

type Props = {
    passwordChanged: (password: string) => void
};
type State = {
    password: string
};

export class PasswordPrompt extends React.Component<Props, State> {
    
    constructor(props: any) {
        super(props);
        this.state = {
            password: ""
        };
    }

    confirmPassword() {
        this.props.passwordChanged(this.state.password)
    }

	public render() {
        return <div className="password-prompt-container">
            <div className="password-prompt">
                <div className="password-prompt-header">
                    Enter password
                </div>
                <form onSubmit={this.confirmPassword.bind(this)} action="#">
                    <div className="password-prompt-body">
                        <input type="password" onChange={(x) => this.setState({ password: x.target.value })}></input>
                    </div>
                    <div className="password-prompt-footer">
                        <div className="button confirm-button" onClick={this.confirmPassword.bind(this)}>Confirm</div>
                    </div>
                </form>
            </div>
        </div>
    }
};
