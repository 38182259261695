import React from "react";
import { ApiService } from "../../../services/ApiService";
import "./ImageLibrary.scss";
import Dropzone from 'react-dropzone'

type Props = {
    api: ApiService,    
    selectedImage: (guid: string) => void,
    closed: () => void
};
type State = {
    images: string[],
    selectedImage: string | null
};

export class ImageLibrary extends React.Component<Props, State> {
    
    constructor(props: any) {
        super(props);
        this.state = {
            images: [],
            selectedImage: null
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    public async fetchData() {
        const images = this.props.api.getImages();

        this.setState({
            images: await images,
        }, () => {
            
        });
    }

    public selectImage(guid: string) {
        this.props.selectedImage(guid);
    }

    public async handleDrop(files: File[]) {
        if (files.length === 0)
            return;

        for (const file of files) {
            const guid = await this.props.api.uploadImage(file);
            if (guid) {
                this.setState({
                    images: [
                        ...this.state.images,
                        guid as string
                    ],
                    selectedImage: guid
                })
            }
        }
    }

	public render() {
        return <div className="image-lib-container">
            <div className="image-lib">
                <div className="image-lib-body">
                    <div className="image-lib-dropzone">
                        <Dropzone onDrop={this.handleDrop.bind(this)}>
                        {({getRootProps, getInputProps}: any) => (
                            <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p>Drop your image file here, or click to select</p>
                                </div>
                            </section>
                        )}
                        </Dropzone>
                    </div>
                    <div className="image-container">
                        { this.state.images.map(x => 
                            <div className="image-wrapper" onClick={() => this.setState({ selectedImage: x })}>
                                <img src={this.props.api.getImageUrl(x)} key={x} alt=""/>
                            </div> 
                        )}
                    </div>                    
                </div>
                <div className="image-lib-sidebar">
                    <div className="image-preview">
                        { this.state.selectedImage ? <>
                            <img src={this.props.api.getImageUrl(this.state.selectedImage ?? "")} alt="preview"/>
                        </> : <></>}
                    </div>
                    <div className="sidebar-buttons">
                        <div className="button button-cancel" onClick={() => this.props.closed()}>Cancel</div>
                        { this.state.selectedImage ? <>
                            <div className="button button-confirm" onClick={() => this.props.selectedImage(this.state.selectedImage as string)}>Select</div>
                        </> : <></>}
                    </div>
                </div>
            </div>
        </div>
    }
};
