import "./wizard.scss";

export class WizardPage {
    constructor(
        public markdown: string,
        public title: string,
        public date: Date | null,
        public thumbnail: string | null
    ) {}
};
