import React from "react";
import { ApiService } from "../../../services/ApiService";
import { ImageLibrary } from "../ImageLibrary/ImageLibrary";
import "./ImagePicker.scss";

type Props = {
    api: ApiService,
    value: string | null,
    onChange: (value: string) => void
};
type State = {
    showLibrary: boolean
};

export class ImagePicker extends React.Component<Props, State> {
    
    constructor(props: any) {
        super(props);
        this.state = {
            showLibrary: false
        };
    }

    componentDidMount() {
        
    }

    public handleImageSelection(image: string) {
        this.setState({
            showLibrary: false
        }, () => {
            this.props.onChange(image);
        })
    }

	public render() {
        return <div className="image-picker">
            <input type="text" readOnly={true} value={this.props.value ?? ""} />
            <button type="button" onClick={() => this.setState({ showLibrary: true })}>Pick</button>
            { this.state.showLibrary ? <>
                <ImageLibrary api={this.props.api} selectedImage={this.handleImageSelection.bind(this)} closed={() => this.setState({ showLibrary: false})}></ImageLibrary>
            </> : <></>}
        </div>
    }
};
