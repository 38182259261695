import React from "react";
import "./page.scss";

type Props = {
    title: string,
    date: string,
    isVertical: boolean,
    children: React.ReactNode,
};
type State = {};

export class Page extends React.Component<Props, State> {
    static defaultProps = {
        title: "",
        date: new Date(),
        isVertical: false
    }

    constructor(props: any) {
        super(props);
        this.state = {};
    }

	public render() {
        return <div className="page">
            <div className={`page-content ${this.props.isVertical ? "page-vertical" : ""}`}>
                { this.props.children }
            </div>
        </div>
    }
};
