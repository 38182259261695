/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Post } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Posts<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Posts
   * @name GetPostsGet
   * @request GET:/Posts
   * @secure
   */
  getPostsGet = (params: RequestParams = {}) =>
    this.request<Post[], any>({
      path: `/Posts`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Posts
   * @name CreatePostPost
   * @request POST:/Posts
   * @secure
   */
  createPostPost = (body: Post, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/Posts`,
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Posts
   * @name UpdatePostPut
   * @request PUT:/Posts
   * @secure
   */
  updatePostPut = (body: Post, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/Posts`,
      method: "PUT",
      body: body,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Posts
   * @name GetPostGet
   * @request GET:/Posts/{id}
   * @secure
   */
  getPostGet = (id?: string, params: RequestParams = {}) =>
    this.request<Post, any>({
      path: `/Posts/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Posts
   * @name DeletePostDelete
   * @request DELETE:/Posts/{id}
   * @secure
   */
  deletePostDelete = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/Posts/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}
