/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HttpClient, RequestParams } from "./http-client";

export class Images<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Images
   * @name GetImageGet
   * @request GET:/Images/{id}
   * @secure
   */
  getImageGet = (id?: string, params: RequestParams = {}) =>
    this.request<File, any>({
      path: `/Images/${id}`,
      method: "GET",
      secure: true,
      format: "blob",
      ...params,
    });
  /**
   * No description
   *
   * @tags Images
   * @name DeleteImageDelete
   * @request DELETE:/Images/{id}
   * @secure
   */
  deleteImageDelete = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/Images/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Images
   * @name GetAllImagesGet
   * @request GET:/Images
   * @secure
   */
  getAllImagesGet = (params: RequestParams = {}) =>
    this.request<string[], any>({
      path: `/Images`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Images
   * @name StoreImagePost
   * @request POST:/Images
   * @secure
   */
  storeImagePost = (body: File, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/Images`,
      method: "POST",
      body: body,
      secure: true,
      format: "json",
      ...params,
    });
}
